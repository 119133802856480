.searchList {
  text-align: left;
  max-width: 500px;
  margin: 0 auto;
}

.searchList ul {
  margin: 1rem 0;
  padding-left: 20px;
  list-style: none;
  display: flex;
  flex-direction: column;
  padding: 0;
}

.searchList li {
  padding: 5px;
  background: rgba(0, 0, 0, 0.1);
}

.searchList li:nth-child(even) {
  background: rgba(0, 0, 0, 0.2);
}

.searchList select {
  width: 100%;
  padding: 5px;
  margin: 1rem 0;
}

.searchList pre {
  max-height: 40vh;
  overflow: auto;
  font-size: 0.8rem !important;
}

.searchList input[type="text"] {
  width: 100%;
  padding: 5px;
}
